<template>
    <div class="live-main" v-loading="connectLoading" element-loading-text="连接中">
        <div class="bg-img-box">
            <img class="bg-img" v-if="userInfo" :class="{ 'bg-blur': liveState === 0 }" :src="userInfo.live_background" />
            <div class="pull-hint" v-if="liveType == 1 && liveState == 1 && !isPushing">等待主播开始直播</div>
        </div>
        <div class="mask" v-if="liveState == 0 || liveState == 3 || liveState == 4"></div>
        <div class="countdown" v-if="liveState == 4">{{ countdown }}</div>
        <div class="container">
            <div id="live-video-container-box" class="live-video-container-box" ref="parentLiveVideo">
                <template v-if="liveType == 2">
                    <div ref="liveVideo" class="live-video" id="live-video"></div>
                </template>
                <!-- <template v-if="liveType == 1">
                    <video ref="liveVideo" id="live-video" class="live-video" preload="auto" playsinline webkit-playsinline></video>
                </template> -->
            </div>
            <div class="content" :class="{ 'play-controls': liveType == 1 && liveState == 1 }">
                <div class="state live-no-start" v-if="liveState === 0">
                    <live-head-user-info key="1" :userInfo="userInfo"></live-head-user-info>
                    <live-no-start-center
                        :viewInfo="viewInfo"
                        :flag="startBtnFlag"
                        @start-live="startButtonHandler"
                    ></live-no-start-center>
                    <live-bottom-action @transfer="transfer" @sendBulletChat="sendBulletChat"></live-bottom-action>
                </div>
                <div class="state live-ing" v-else-if="liveState === 1 || liveState === 4 || liveState == 3">
                    <live-head-user-info
                        key="2"
                        :userInfo="userInfo"
                        :allPerson="allPerson"
                        :liveState="liveState"
                    ></live-head-user-info>
                    <liveing-center
                        :goods="currentGoods"
                        :couponList="viewCouponList"
                        :addedCoupon="addedCoupon"
                        :currentBag="currentBag"
                        :bulletChatList="bulletChatList"
                        :liveState="liveState"
                        @openCoupon="openAddedCoupon"
                        @openBag="openAddedBag"
                    ></liveing-center>
                    <live-bottom-action @transfer="transfer" @sendBulletChat="sendBulletChat"></live-bottom-action>
                </div>
                <div class="state live-end" v-else-if="liveState === 2">
                    <live-end-center :user-info="userInfo" :live-duration="liveDuration"></live-end-center>
                </div>
            </div>
            <live-pause-confirm :is-show.sync="showPauseConfirmModal" @live-pause="() => sendUpdateLiveState(3)"></live-pause-confirm>
            <live-pause-countdown :is-show.sync="showPauseCountdown" @live-continue="() => sendUpdateLiveState(1)">
            </live-pause-countdown>
            <live-modal
                :is-show.sync="showEndConfirmModal"
                :button-config="['取消', '结束直播']"
                :confirm-handler="() => sendUpdateLiveState(2)"
            >
                观众正在赶来的路上，确定结束直播？
            </live-modal>
            <lucky-bag-popup
                v-if="listInfo"
                :is-show.sync="showLuckyBagPopup"
                :list="viewBagList"
                @release="release"
                @animateEnd="popupAnimateEnd"
            ></lucky-bag-popup>
            <goods-popup
                v-if="listInfo"
                :is-show.sync="showGoodsPopup"
                :list="listInfo.goodsList"
                :couponList="listInfo.couponList"
                @openCouponPopup="goodsToCouponPopup"
                @release="release"
                @fall="goodsFall"
            ></goods-popup>
            <coupon-popup
                :is-show.sync="showCouponPopup"
                :list="viewCouponList"
                @animateEnd="popupAnimateEnd"
                @release="release"
            ></coupon-popup>

            <!-- 直播飘屏效果 -->
            <div class="like-container">
                <div class="like-icon" 
                    v-for="item in likeIcons" 
                    :key="item.index" 
                    :style="{ 
                        '--rotate': `${item.rotate}deg`,
                        '--rand-y': `-${item.randY}px`,
                        '--rand-x': `${item.randX}px`, 
                        '--animation-time': `${item.animationTime}s`, 
                        '--right-x': `${item.rightX}px`,
                        '--bottom-x': `${item.bottomX}px`
                    }"
                >
                    <img src="../../../assets/image/digg-icon.png" alt="">
                    <!-- <i class="iconfont">&#xec8c;</i> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import LiveNoStartCenter from "./liveMain/LiveNoStartCenter";
import LiveingCenter from "./liveMain/LiveingCenter";
import LiveEndCenter from "./liveMain/LiveEndCenter";
import LiveModal from "./liveMain/containers/LiveModal";
import LuckyBagPopup from "./liveMain/LuckyBagPopup";
import GoodsPopup from "./liveMain/GoodsPopup";
import CouponPopup from "./liveMain/CouponPopup";

import LiveHeadUserInfo from "./liveMain/LiveHeadUserInfo";
import LiveBottomAction from "./liveMain/LiveBottomAction";

import LivePauseConfirm from "./liveMain/LivePauseConfirm";
import LivePauseCountdown from "./liveMain/LivePauseCountdown";
import { getSteamLocation, liveRoom_list } from "@/utils/apis.js";
import { setTimeout, clearTimeout } from "timers";
let that;
export default {
    name: "LiveMain",
    data() {
        return {
            wssHost: localStorage.getItem("websocket_host"),
            firstLoad: true,
            firstSetData: true,
            countdown: 3,
            liveState: 0, //0未开始， 1直播中， 2直播结束，3直播暂停， 4直播倒计时
            liveType: void 0, //0未知 1手机直播 2电脑端直播'
            userInfo: null,
            viewInfo: null,
            listInfo: null,
            currentGoodsId: void 0,
            addedGoodsList: [],
            connectLoading: true,
            showHiddenMask: false,
            allPerson: 0,
            startBtnFlag: true,
            releaseFlag: true,
            //定时器
            bagTimer: void 0,
            scriptTimer: void 0,
            beatTimer: void 0,
            adTimer: void 0,
            bulletChatTimer: {
                normal: void 0,
                answer: void 0,
            },
            answerTimer: void 0,
            countdownTimer: void 0,
            //弹幕相关
            tempBulletChat: [],
            oldBulletChat: [],
            //左侧脚本控制相关
            scriptCarousel: [],
            splitIndex: 0,
            scriptIndex: 0,
            //语音识别
            speechSDK: null,
            snippetStr: [],
            answerStr: [],
            //websocket
            ws: null,
            commonParams: {
                user_id: localStorage.getItem("studentId"),
                script_id: localStorage.getItem("scriptId"),
                train_id: localStorage.getItem("train_id"),
                advert_id: localStorage.getItem("advert_id"),
            },
            // commonParams: {
            //     room_id: 39,
            //     user_id: 145,
            //     script_id: 225,
            //     train_id: 75,
            //     advert_id: 12,
            // },
            //推拉流
            pushUrl: "",
            pullUrl: null, //key: m3u8, webrtc
            isPushing: 0,
            livePusher: null,
            //dialog
            couponGoodsId: "",
            couponOrigin: 0,
            bagOrigin: 0,
            showPauseConfirmModal: false,
            showPauseCountdown: false,
            showEndConfirmModal: false,
            showLuckyBagPopup: false,
            showGoodsPopup: false,
            showCouponPopup: false,
            audioValid: false,
            likeIcons: [],
        };
    },
    props: {
        roomId: {
            type: Number,
            default: void 0,
        },
    },

    computed: {
        isDev() {
            return process.env.NODE_ENV === "development";
        },
        liveVideo() {
            const liveVideo = this.livePusher;
            if (!liveVideo) return null;
            const pushObj = {
                start: () => liveVideo.startPush(this.pushUrl),
                stop: () => {
                    if (liveVideo) {
                        // liveVideo.stopPush();
                        // liveVideo.stopCamera();
                        // liveVideo.stopMicrophone();
                        liveVideo.destroy();
                    }
                },
            };
            const pullObj = {
                start: () => liveVideo.play(),
                stop: () => {
                    // liveVideo.pause();
                    liveVideo.dispose();
                },
            };
            return this.liveType == 1 ? pullObj : pushObj;
        },
        currentScript() {
            const { scriptCarousel, scriptIndex } = this;
            const target = scriptCarousel[scriptIndex];
            if (!target) return {};
            return target;
        },
        currentGoods() {
            const { addedGoodsList, listInfo, currentScript } = this;
            if (addedGoodsList.length <= 0 || !listInfo) return null;
            const goods = listInfo.goodsList.find((item) => item.maskStatus == 1);
            return goods;
            // return listInfo.goodsList[0];
        },
        currentBag() {
            const { listInfo } = this;
            if (!listInfo) return null;
            const bag = listInfo.bagList.find((item) => item.status == 1);
            return bag && bag.newCountdown > 0 ? bag : null;
            // return bag ? bag : null;
        },
        bulletChatList() {
            return this.oldBulletChat.concat(this.tempBulletChat).sort((a, b) => a.timestamp - b.timestamp);
        },
        packCouponList() {
            const { listInfo } = this;
            if (!listInfo) return [];
            const list = listInfo.couponList.reduce((arr, item) => {
                const add = {
                    couponType: item.coupon_type,
                    couponName: item.coupon_name,
                    everyoneCount: item.everyone_count,
                };
                const temp = item.child.map((subItem) => Object.assign({}, subItem, add));
                return arr.concat(temp);
            }, []);
            return list;
        },
        viewCouponList() {
            const { couponGoodsId, couponOrigin, packCouponList, addedCoupon } = this;
            let list = packCouponList;
            if (couponGoodsId) {
                list = list.filter((item) => item.goods_id == couponGoodsId);
            } else if (couponOrigin == 1) {
                list = addedCoupon;
            }
            return list;
        },
        liveDuration() {
            const { scriptCarousel, scriptIndex } = this;
            const currentScript = scriptCarousel[scriptIndex];
            if (scriptCarousel.length <= 0) return 0;
            return currentScript ? currentScript.endTime : scriptCarousel.slice(-1)[0].endTime;
        },
        viewBagList() {
            const { listInfo, bagOrigin, currentBag } = this;
            if (!listInfo) return [];
            return bagOrigin == 0 ? listInfo.bagList : [currentBag];
        },
        addedCoupon() {
            return this.packCouponList.filter((item) => item.status != 0);
        },
        wsActions() {
            const room_id = this.roomId;
            const { user_id, script_id, train_id, advert_id } = this.commonParams;
            const getParams = (params) => ({ room_id, ...this.commonParams, ...params });
            const obj = {
                bind: { type: "bind", room_id, ...this.commonParams },
                startLive: { type: "start", room_id, user_id, script_id, advert_id, train_id },
                updateLiveState: {
                    type: "update_room_status",
                    status: void 0,
                    room_id,
                    ...this.commonParams,
                },
                getNewData: { type: "data", room_id },
                getScriptInfo: { type: "get_script_info", room_id, user_id, script_id, advert_id, train_id },
                getListData: { type: "get_student_data", user_id, train_id, script_id, room_id },
                //genre: 6商品上架、7发布优惠券、8发布福袋
                operates: {
                    type: "operation",
                    genre: void 0,
                    genre_id: void 0,
                    // goods_id: void 0,
                    room_id,
                    train_id,
                    user_id,
                    script_id,
                },
                getAddedGoods: { type: "get_student_now_shelves_goods", user_id, train_id, script_id, room_id },
                changeCoupon: {
                    type: "send_coupon",
                    // id: void 0,
                    // status: void 0
                    room_id,
                },
                ad: {
                    type: "advert",
                    advert_id: void 0,
                    genre: 11,
                    room_id,
                    user_id,
                    train_id,
                },
                speech: {
                    type: "recognition",
                    genre: void 0,
                    genre_id: void 0, //脚本片段ID
                    speech_content: void 0,
                    room_id,
                    train_id,
                    user_id,
                    script_id,
                },
                getBulletChat: { type: "get_all_barrage", train_id, user_id },
                sendBulletChat: {
                    type: "send_barrage",
                    msg: void 0, //barrage, nickname, avatar, send_msg_user_id
                    room_id,
                },
                saveBulletChat: {
                    type: "save_barrage",
                    data: [], //barrage, nickname, avatar, send_msg_user_id
                    train_id,
                    user_id,
                    room_id,
                },
                useCurrentGoods: {
                    type: "get_interpretation_goods",
                    goods_id: void 0,
                    room_id,
                },
                getPushState: {
                    type: "check_is_has_push",
                    room_id,
                    ...this.commonParams,
                },
                updateGoodsState: getParams({
                    type: "update_goods_status",
                    status: void 0,
                    goods_id: void 0,
                })
            };
            return obj;
        },
    },
    components: {
        LiveNoStartCenter,
        LiveingCenter,
        LiveEndCenter,
        LiveModal,
        LuckyBagPopup,
        GoodsPopup,
        CouponPopup,
        LiveHeadUserInfo,
        LiveBottomAction,
        LivePauseConfirm,
        LivePauseCountdown
    },
    watch: {
        liveState: {
            handler(newVal) {
                const maskState = newVal == 2 ? true : false;
                this.showHiddenMask = maskState;
                this.$emit("setProp", { key: "liveState", value: newVal });
            },
            immediate: true,
        },
        currentScript: {
            handler(newVal) {
                this.$emit("setProp", { key: "currentScript", value: newVal });
            },
            deep: true,
            immediate: true,
        },
        scriptCarousel: {
            handler(newVal) {
                this.$emit("setProp", { key: "scriptCarousel", value: newVal });
            },
            deep: true,
            immediate: true,
        },
        bulletChatList: {
            handler(newVal) {
                this.$emit("bulletChatChange", newVal);
            },
            deep: true,
            immediate: true,
        },
        viewInfo: {
            handler(newVal) {
                if (newVal) {
                    this.$emit("setProp", { key: "liveTime", value: newVal.onlive });
                }
            },
            deep: true,
            immediate: true,
        },
        currentBag: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.setBagCountdown();
                }
            },
            deep: true,
            immediate: true,
        },
        roomId: {
            handler(newVal) {
                if (newVal) {
                    this.roomId = newVal;
                }
            },
            immediate: true,
        },
    },
    methods: {
        async init() {
            await this.getLiveSteamData();
            await this.setupWSConnect();
            this.$bus.$on("countdownEnd", () => this.liveType == 2 && this.sendUpdateLiveState(2));
        },
        /**设置websocket对象 */
        async setupWSConnect() {
            const disposeFun = {
                bind: this.bindRoomHandler,
                get_script_info: this.scriptInit,
                get_student_data: this.setListData,
                data: this.setReloadNewData,
                start: this.startLiveHandler,
                recognition: this.setChangeData,
                operation: this.setChangeData,
                advert: this.setChangeData,
                send_barrage: this.setBulletChatList,
                get_all_barrage: (res) => this.$set(this, "oldBulletChat", res.data.list),
                save_barrage: () => {
                    this.oldBulletChat.push(...this.tempBulletChat);
                    this.tempBulletChat.length = 0;
                },
                likes: this.wsLikes,
                check_is_has_push: (res) => {
                    if (res.code != 200) {
                        return void 0;
                    }
                    this.isPushing = res.data.result;
                    if (this.liveType == 1) {
                        const clearPull = () => {
                            const parentNode = this.$refs.parentLiveVideo;
                            parentNode && this.$set(parentNode, "innerHTML", "");
                        };
                        this.isPushing == 1 ? this.setLivePull() : clearPull();
                    }
                },
                update_room_status: this.updateLiveStateHandler,
                update_goods_status: (res) => {
                    this.releaseFlag = true;
                    this.setListData(res);
                },
                now_genre_id: this.setCurrentScript
            };
            this.ws = new WebSocket(this.wssHost);
            this.ws.addEventListener("close", (e) => {
                console.log("链接中断", e);
                if (e.code != 1000 && this.liveState != 2) {
                    this.connectLoading = true;
                    this.setupWSConnect();
                }
            });
            this.ws.addEventListener("open", () => {
                this.liveRoomInit();
                this.connectLoading = false;
            });
            this.ws.addEventListener("error", (err) => {
                console.log("链接错误", err);
            });
            this.ws.addEventListener("message", (res) => {
                res = JSON.parse(res.data);
                if (!res.data) {
                    return void 0;
                }
                // console.log(`type: ${res.data.web_type}, `, res.data);
                const type = res.data.web_type;
                const fun = type ? disposeFun[type] : "";
                fun && fun(res);
            });
        },
        /**直播间初始化，获取脚本，列表等信息 */
        liveRoomInit() {
            const { ws, wsActions } = this;
            ws.send(JSON.stringify(wsActions.bind));
            if (this.firstLoad) {
                this.firstLoad = false;
                ws.send(JSON.stringify(wsActions.getScriptInfo));
                ws.send(JSON.stringify(wsActions.getListData));
                ws.send(JSON.stringify(this.wsActions.getBulletChat));
                ws.send(JSON.stringify(wsActions.getNewData));
                this.setBeatCheck();
            }
        },
        /**心跳检测 */
        setBeatCheck() {
            this.beatTimer && clearTimeout(this.beatTimer);
            this.beatTimer = setTimeout(() => {
                this.ws.send(JSON.stringify({ type: "get_now_time" }));
                // this.liveState == 1 && this.setBeatCheck();
                this.setBeatCheck();
            }, 30000);
        },
        /**响应开始直播处理 */
        startLiveHandler(res) {
            this.startBtnFlag = true;
            const errMap = { 400: res.msg, 206: "请勿重复直播" };
            const errMsg = errMap[res.code];
            if (errMsg) {
                this.$message.warning(errMsg);
                this.stopResource();
                return void 0;
            }
            this.sendUpdateLiveState(1);
            this.setChangeData(res);
        },
        /**响应更新直播间状态处理 */
        updateLiveStateHandler(res) {
            if (res.code != 200) {
                this.$message.warning(res.msg);
                this.connectLoading = false;
                return void 0;
            }
            if (res.data.status == 4 || this.checkLiveStateEqual(res.data.status)) {
                this.connectLoading = false;
                return void 0;
            }
            const { stopLive, liveReadyWork } = this;
            const { status } = res.data;
            status != 1 && this.$set(this, "liveState", status);
            this.hiddenAllWindow();
            if(status == 1) {
                this.scriptCarousel.length > 0 && liveReadyWork();
            }else if(status == 2) {
                stopLive(2);
            }else if(status == 3) {
                stopLive(3);
                this.showPauseCountdown = true;
            }
            this.connectLoading = false;
        },
        /**响应绑定直播间处理 */
        bindRoomHandler(result) {
            if(result.code != 200) {
                this.$message.error(result.msg);
                return;
            }
            const res = result.data[0];
            const status = res.status;
            if (this.checkLiveStateEqual(status)) return void 0;
            this.liveType = res.live_type;
            this.isPushing = res.is_has_push;
            this.$set(this, "userInfo", res);
            if (status == 1) {
                const pullFun = () => {
                    this.isPushing == 1 && this.setLivePull();
                    // res.is_has_push == 1 && this.$set(this, "liveState", 1);
                    this.liveState = 1;
                };
                this.liveType == 2 ? this.sendUpdateLiveState(1) : pullFun();
            } else if (status == 2) {
                this.liveState = 2;
            } else if (status == 3) {
                this.liveState = 3;
                this.showPauseCountdown = true;
            }
        },
        checkLiveStateEqual(newState) {
            return newState != 0 && this.liveState == newState;
        },
        /**包装脚本数据 */
        scriptInit(res) {
            if(res.code != 200) {
                this.$message.error(res.msg);
                return void 0;
            }
            const temp = new Map();
            res.data.advert_list.forEach((item) => temp.set(item.minute, Object.assign(item, { second: item.minute * 60 })));
            res.data.ADList = Array.from(temp)
                .map((item) => item[1])
                .sort((a, b) => a.minute - b.minute);
            this.viewInfo = res.data;
            this.packBulletChatList(this.viewInfo.new_script_data);
            const opeTypes = [2];
            const getRandomList = (time, num) => {
                let allTime = time;
                let mean = allTime / num;
                const timeList = [];
                for (let i = 0; i < num; i++) {
                    const random = Math.floor(Math.random() * mean);
                    timeList.push(random);
                    allTime -= random;
                }
                return timeList;
            };
            const scriptList = this.viewInfo.new_script_data
                .filter((item) => !opeTypes.includes(item.script_type))
                .map((item, index, arr) => {
                    const getTime = () => {
                        const before = arr[index - 1];
                        return item.start_time == before.end_time ? item.start_time : before.end_time;
                    };
                    const haveDiffTime = index == 0 ? item.start_time : getTime();
                    const common = {
                        ...item,
                        startTime: item.start_time,
                        endTime: item.end_time,
                        isChild: item.genre == 1 || item.genre == 2 ? item.genre : void 0,
                        sleepTime: item.end_time - haveDiffTime,
                    };
                    if (item.barrage && item.barrage instanceof Array) {
                        const sleepList = getRandomList(common.sleepTime, item.barrage.length);
                        const bulletChat = item.barrage.reduce(
                            (sum, subItem, subIndex) =>
                                sum.concat([this.createRandomBulletChat({ ...subItem, answer_time: sleepList[subIndex] })]),
                            []
                        );
                        common.bulletChat = bulletChat.map((subItem, subIndex) => ({
                            answer_time: sleepList[subIndex],
                            list: subItem,
                        }));
                    }
                    index == arr.length - 1 && this.$set(common, "end", 2);
                    return common;
                });
            this.scriptCarousel = scriptList;
        },
        /**包装弹幕列表 */
        packBulletChatList(scriptData) {
            const goodsBulletChat = new Map();
            const goodsScript = new Map();
            const setFun = (item, map) => {
                const target = map.get(item.goods_id);
                target ? target.push(item) : map.set(item.goods_id, [item]);
            };
            const getArr = (arr) => {
                const { barrage_avatar, barrage_nickname } = this.viewInfo;
                const list = arr.reduce((list, item, index) => {
                    const temp = [];
                    const speechParams = {
                        genre: 2,
                        genre_id: item.genre_id,
                        goods_id: item.goods_id,
                    };
                    const template = {
                        answer_time: item.answer_time,
                        speechParams,
                        ...speechParams,
                    };
                    template.list = this.createRandomBulletChat(item);
                    temp.push(template);
                    return list.concat(temp);
                }, []);
                return list;
            };
            scriptData.forEach((item) => {
                item.script_type == 2 && setFun(item, goodsBulletChat);
                item.script_type == 10 && setFun(item, goodsScript);
            });
            const bulletChatList = Array.from(goodsBulletChat);
            bulletChatList.forEach((item) => {
                const [goods_id, arr] = item;
                let script = goodsScript.get(goods_id);
                script = script && script.length > 0 ? script[0] : null;
                if (!script) return void 0;
                this.$set(script, "answerBulletChat", getArr(arr));
            });
        },
        /**创建随机弹幕 */
        createRandomBulletChat(item) {
            const { barrage_avatar, barrage_nickname } = this.viewInfo;
            let allTime = item.answer_time;
            const list = [];
            const keys = Object.keys(item);
            const length = keys.includes("appear_count") ? item.appear_count : item.number;
            for (let i = 0; i < length; i++) {
                const avatarIndex = Math.floor(Math.random() * barrage_avatar.length);
                const nicknameIndex = Math.floor(Math.random() * barrage_nickname.length);
                const sleepTime = Math.floor(Math.random() * allTime);
                allTime -= sleepTime;
                const bulletChatParams = {
                    barrage: item.script_content ? item.script_content : item.content,
                    nickname: barrage_nickname[nicknameIndex],
                    avatar: barrage_avatar[avatarIndex],
                    send_msg_user_id: 0,
                };
                list.push(Object.assign({}, bulletChatParams, { sleepTime, bulletChatParams }));
            }
            return list;
        },
        /**存储列表信息 */
        setListData(res) {
            if (res.code != 200) {
                this.$message.error(res.msg);
                return void 0;
            }
            const {
                student_bag_list: bagList,
                student_coupon_list: couponList,
                choose_goods_data: goodsList,
                student_shelves_goods: addedGoodsList,
            } = res.data;
            this.addedGoodsList = addedGoodsList;
            bagList.forEach((item, index) => {
                this.$set(item, "newCountdown", item.has_countdown);
            });
            this.listInfo = Object.assign({}, { bagList, couponList, goodsList });
            this.setGoodsList();
        },
        /**包装商品列表 */
        setGoodsList() {
            //0未发布 1已发布 2讲解中 3已讲解
            //0彩色发布，1彩色下架，2灰色发布，3灰色下架
            //0无遮罩，1讲解中，2已讲解
            this.$nextTick(() => {
                if(!this.userInfo) return;
                const { goods_resourse } = this.userInfo;
                this.listInfo.goodsList.forEach((item, index) => {
                    const isAdded = this.addedGoodsList
                        .filter((subItem) => subItem.status != 3)
                        .some((subItem) => subItem.goods_id == item.goods_id);
                    const isCurrent = this.currentScript.goods_id == item.goods_id;
                    const isActionScript = this.currentScript.script_type == 6;
                    const isActionEnd = this.scriptCarousel
                        .slice(0, this.scriptIndex)
                        .some((subItem) => subItem.goods_id == item.goods_id);
                    const isScriptEnd =
                        !isCurrent &&
                        this.scriptCarousel.slice(0, this.scriptIndex).some((subItem) => subItem.goods_id == item.goods_id);
                    let status = isAdded && isActionEnd ? 3 : !isAdded && isActionEnd ? 2 : isAdded ? 1 : 0;
                    let maskStatus = isScriptEnd ? 2 : isAdded && isCurrent ? 1 : 0;
                    // console.log(isAdded, isCurrent, isActionEnd, isScriptEnd);
                    // console.log(status, maskStatus);
                    this.$set(item, "status", status);
                    this.$set(item, "maskStatus", maskStatus);
                    const target = goods_resourse.find((subItem) => subItem.goods_id == item.goods_id);
                    item.goods_info.paster = target ? target.goods_live_patch : void 0;
                });
                const currentGoods = this.listInfo.goodsList.find((item) => item.maskStatus == 1);
                const params = { goods_id: currentGoods ? currentGoods.goods_id : "" };
                this.ws.send(JSON.stringify(Object.assign({}, this.wsActions.useCurrentGoods, params)));
            });
        },
        /**设置语音识别信息 */
        setSpeech() {
            const params = {
                signCallback: signCallback, // 鉴权函数
                // 用户参数
                secretid: config.secretId,
                appid: config.appId,
                // 实时识别接口参数
                engine_model_type: "16k_zh", // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
                // 以下为非必填参数，可跟据业务自行修改
                voice_format: 1,
                hotword_id: "08003a00000000000000000000000000",
                needvad: 1,
                filter_dirty: 1,
                filter_modal: 2,
                filter_punc: 0,
                convert_num_mode: 1,
                word_info: 2,
            };
            let count = 0;
            return new Promise((resolve, reject) => {
                const connect = async () => {
                    if (this.speechSDK) {
                        this.speechSDK.stop();
                        this.speechSDK = null;
                    }
                    this.speechSDK = new WebAudioSpeechRecognizer(params);
                    this.splitIndex = 0;
                    this.snippetStr.length = 0;
                    const setSnippetStr = (res) => {
                        if (this.liveState == 1) {
                            this.snippetStr[res.index] = res.voice_text_str;
                            this.currentScript.script_type == 10 && this.$set(this.answerStr, res.index, res.voice_text_str);
                        }
                    };
                    this.speechSDK.OnRecognitionStart = (res) => {
                        console.log("开始识别", res);
                        resolve({ state: true });
                    };
                    this.speechSDK.OnRecognitionComplete = (res) => console.log("识别结束", res);
                    // // 识别变化时
                    this.speechSDK.OnRecognitionResultChange = setSnippetStr;
                    // 一句话结束
                    this.speechSDK.OnSentenceEnd = setSnippetStr;
                    this.speechSDK.start();
                    // 识别错误
                    this.speechSDK.OnError = async (res) => {
                        console.log("识别失败", res);
                        if (this.liveType == "2" && res.type == "close" && this.liveState != 4 && count < 10) {
                            await connect();
                            this.speechSDK.start();
                        }
                    };
                    count++;
                };
                connect();
            });
        },
        /**设置左侧脚本轮换 */
        setScriptTimer() {
            // if (this.isDev) {
            //     return void 0;
            // }
            const { scriptCarousel, viewInfo, ws, wsActions, tempBulletChat, liveType, currentGoods, scriptIndex } = this;
            const currentScript = scriptCarousel[scriptIndex];
            const sendFun = () => {
                const content = this.snippetStr.join("");
                const add = {
                    speech_content: content.slice(this.splitIndex),
                    genre: currentScript.script_type,
                    genre_id: currentScript.genre_id,
                    is_child: currentScript.isChild,
                    end: currentScript.end,
                };
                const action = Object.assign({}, this.wsActions.speech, add);
                this.ws.send(JSON.stringify(action));
                this.splitIndex = content.length;
                // this.snippetStr.length = 0;
            };
            const saveBulletChat = () => {
                const params = Object.assign({}, this.wsActions.saveBulletChat, { data: tempBulletChat });
                ws.send(JSON.stringify(params));
            };
            const sendBulletChat = () => {
                const tempScript = scriptCarousel[this.scriptIndex];
                tempScript.answerBulletChat && this.setBulletChatTimer(tempScript.answerBulletChat, "answer");
                tempScript.bulletChat && tempScript.bulletChat.length > 0 && this.setBulletChatTimer(tempScript.bulletChat, "normal");
            };
            const sendGoods = (goodsId) => {
                const currentScript = scriptCarousel[scriptIndex];
                const params = {
                    genre_id: 0,
                    genre: 6,
                    goods_id: goodsId,
                    is_correct: Boolean(currentScript.script_type == 6 && currentScript.goods_id == goodsId) ? 1 : 2,
                };
                params.is_correct == 1 && this.$set(params, "genre_id", currentScript.genre_id);
                ws.send(JSON.stringify(Object.assign({}, wsActions.operates, params)));
            };
            this.scriptTimer && clearTimeout(this.scriptTimer);
            const actions = [6, 7, 8];
            const ad = viewInfo.advert_list.find(
                (item) => item.second >= currentScript.startTime && item.second <= currentScript.endTime
            );
            if (liveType == 2) {
                sendBulletChat();
                ad && this.setADTimer(ad, currentScript);
            }
            this.scriptTimer = setTimeout(() => {
                this.setGoodsList();
                tempBulletChat.length > 0 && saveBulletChat();
                !actions.includes(currentScript.script_type) && liveType == 2 && sendFun();
                currentScript.script_type == 6 && this.currentGoods && sendGoods(that.currentGoods.goods_id);
                currentScript &&
                    currentScript.goods_id &&
                    scriptCarousel[scriptIndex + 1] &&
                    currentScript.goods_id != scriptCarousel[scriptIndex + 1].goods_id &&
                    this.updateGoodsState(currentScript.goods_id, currentGoods ? 2 : 3);
                this.scriptIndex++;
                this.scriptIndex < scriptCarousel.length ? this.setScriptTimer() : this.sendUpdateLiveState(2);
            }, scriptCarousel[this.scriptIndex].sleepTime * 1000);
        },
        /**设置广告请求的倒计时 */
        setADTimer(ad, currentScript) {
            const { ws, wsActions } = this;
            this.adTimer && clearTimeout(this.adTimer);
            this.adTimer = setTimeout(() => {
                const params = Object.assign({}, wsActions.ad, { genre_id: ad.id });
                ws.send(JSON.stringify(params));
            }, (ad.second - currentScript.startTime) * 1000);
        },
        /**弹幕定时器 */
        async setBulletChatTimer(list, key) {
            this.bulletChatTimer[key] && clearTimeout(this.bulletChatTimer[key]);
            const { ws, wsActions, currentScript } = this;
            const sendFun = async (item) =>
                new Promise((res, rej) => {
                    this.bulletChatTimer[key] = setTimeout(() => {
                        const params = Object.assign({}, wsActions.sendBulletChat, { msg: Object.assign(item.bulletChatParams, { timestamp: +new Date() }) });
                        ws.send(JSON.stringify(params));
                        res();
                    }, item.sleepTime * 1000);
                });
            for (let i = 0; i < list.length; i++) {
                if (this.liveState != 1) break;
                const item = list[i];
                this.answerStr.length = 0;
                if (key == "answer") {
                    this.answerTimer = setTimeout(() => {
                        const params = Object.assign({}, wsActions.speech, item.speechParams, {
                            speech_content: this.answerStr.join(""),
                        });
                        ws.send(JSON.stringify(params));
                    }, item.answer_time * 1000);
                }
                for (let j = 0; j < item.list.length; j++) {
                    if (this.liveState != 1) break;
                    await sendFun(item.list[j]);
                }
            }
        },
        /**设置福袋倒计时 */
        setBagCountdown() {
            const { listInfo, currentBag } = this;
            if (!currentBag) return void 0;
            const bag = listInfo.bagList.findIndex((item) => item.id == currentBag.id);
            this.bagTimer && clearTimeout(this.bagTimer);
            this.bagTimer = setTimeout(() => {
                listInfo.bagList[bag].newCountdown--;
                listInfo.bagList[bag].newCountdown > 0 && this.setBagCountdown();
            }, 1000);
        },
        /**设置右边信息 */
        setChangeData(res) {
            if (typeof res == "string" || res.code != 200) return void 0;
            const { fans, like, online_total_number } = res.data;
            const getValue = (value) => (value ? value : 0);
            this.$set(this.userInfo, "fans", getValue(fans));
            this.$set(this.userInfo, "like", getValue(like));
            this.$set(this, "allPerson", getValue(online_total_number));
            this.$emit("changeData", res);
            // this.ws.send(JSON.stringify(this.wsActions.getScriptInfo));
        },
        /**发送弹幕请求 */
        sendBulletChat({ value, isPerson }) {
            const userId = localStorage.getItem("studentId");
            const hostUrl = localStorage.getItem("host_url");
            const addParams = {
                msg: {
                    origin: 1,
                    barrage: value,
                    nickname: this.userInfo.nickname,
                    avatar: `${hostUrl}${this.userInfo.avatar}`,
                    send_msg_user_id: userId,
                    timestamp: new Date().getTime()
                },
            };
            const params = Object.assign({}, this.wsActions.sendBulletChat, addParams);
            this.ws.send(JSON.stringify(params));
        },
        /**设置弹幕列表 */
        setBulletChatList(res) {
            if (res.data.send_msg_user_id && res.data.origin == 2) {
                this.oldBulletChat.push(res.data);
            } else if (res.data.send_msg_user_id && res.data.origin == 1) {
                this.oldBulletChat.push(res.data);
                const params = Object.assign({}, this.wsActions.saveBulletChat, { data: [res.data] });
                this.ws.send(JSON.stringify(params));
            } else {
                this.tempBulletChat.push(res.data);
            }
        },
        /**刷新页面设置 */
        setReloadNewData(res) {
            if (!this.firstSetData) return;

            this.firstSetData = false;
            // return void 0;
            if (res.code != 200 || this.scriptCarousel.length <= 0) return void 0;
            // if (obj.genre_id == this.scriptCarousel.slice(-1)[0].genre_id) return void 0;
            const obj = res.data;
            const scriptIndex = this.scriptCarousel.findIndex((item) => item.genre_id == obj.genre_id);
            this.scriptIndex = scriptIndex != -1 ? scriptIndex : 0;
            this.setChangeData({ data: obj, code: 200 });
            this.scriptCarousel[this.scriptIndex] && this.$bus.$emit("liveingReload", this.scriptCarousel[this.scriptIndex]);
        },
        setCurrentScript(res) {
            if (res.code != 200 || this.scriptCarousel.length <= 0) return void 0;
            // this.setGoodsList();
            const scriptIndex = this.scriptCarousel.findIndex((item) => item.genre_id == res.data.now_genre_id);
            this.scriptIndex = scriptIndex != -1 ? scriptIndex : 0;
            this.scriptCarousel[this.scriptIndex] && this.$bus.$emit("liveingReload", this.scriptCarousel[this.scriptIndex]);
        },
        /**获取推拉流地址 */
        async getLiveSteamData() {
            const res = await getSteamLocation();
            this.pushUrl = res.data.push_url.replace("rtmp", "webrtc");
            this.pullUrl = {
                m3u8: res.data.play_url.http_two_rtmp,
                webrtc: res.data.play_url.webrtc_rtmp,
            };
        },
        /**设置推拉流对象 */
        setLivePusher() {
            const pushFun = (resolve, reject) => {
                const livePusher = new TXLivePusher();
                livePusher.setRenderView("live-video");
                livePusher.setVideoQuality("720p");
                livePusher.setAudioQuality("standard");
                livePusher.setProperty("setVideoFPS", 30);
                const gatherStates = [false, false];
                const gatherCallback = (index) => {
                    gatherStates[index] = true;
                    const valid = !gatherStates.includes(false);
                    valid && resolve({ state: true, msg: "" });
                };
                livePusher.startCamera();
                livePusher.startMicrophone();
                livePusher.setObserver({
                    onCaptureFirstAudioFrame: () => gatherCallback(0),
                    onCaptureFirstVideoFrame: () => gatherCallback(1),
                    onPushStatusUpdate: (status, msg) => {
                        if (this.liveState == 1 && msg == "Disconnected") {
                            this.livePusher.startPush();
                        }
                    },
                    onWarning: (code, msg) => {
                        const errMsgs = {
                            NotFoundError: "没有找到麦克风或摄像头设备",
                            NotAllowedError: "请确保给予麦克风和摄像头设备的权限",
                            common: "无法获取麦克风和摄像头设备，请尝试刷新网页",
                        };
                        let errmsg = errMsgs[msg];
                        errmsg = errmsg ? errmsg : errMsgs.common;
                        resolve({ state: false, msg: errmsg });
                    },
                });
                this.livePusher = livePusher;
            };
            return new Promise(pushFun);
        },
        async setLivePull() {
            const createVideoContainer = () => {
                return new Promise((res, rej) => {
                    const parentNode = this.$refs.parentLiveVideo;
                    parentNode && this.$set(parentNode, "innerHTML", "");
                    const video = document.createElement("video");
                    const openAudio = (valid) => {
                        if(!this.audioValid)
                            this.audioValid = true;
                        if(!valid)
                            return;
                        video.muted = false;
                        video.play();
                    };
                    video.setAttribute("ref", "liveVideo");
                    video.id = "live-video";
                    video.className = "live-video";
                    video.preload = "auto";
                    video.muted = "muted";
                    video.setAttribute("playsinline", "");
                    video.setAttribute("webkit_playsinline", "");
                    parentNode.appendChild(video);
                    res();
                    video.addEventListener("loadeddata", () => {
                        !this.audioValid ? this.$emit("openAudioDialog", openAudio) : openAudio(true)
                    });
                });
            };
            await createVideoContainer();
            if (this.liveVideo) {
                try {
                    console.log(this.liveVideo);
                    this.liveVideo.stop();
                } catch (e) {
                    console.log(e);
                }
            }
            const player = TCPlayer("live-video", {
                sources: [
                    {
                        // 快直播地址
                        src: this.pullUrl.webrtc,
                    },
                    {
                        // HLS直播地址
                        src: this.pullUrl.m3u8,
                    },
                ],
                licenseUrl: "https://license.vod2.myqcloud.com/license/v2/1305294767_1/v_cube.license",
                autoplay: true,
                poster: this.userInfo.live_background,
                // controls: false,
                // defaultRes: "HD",
                controlBar: {
                    playToggle: false,
                    progressControl: false,
                    currentTimeDisplay: false,
                    durationDisplay: false,
                    timeDivider: false,
                    playbackRateMenuButton: false,
                    QualitySwitcherMenuButton: false,
                }
                // 可配置参数说明 https://cloud.tencent.com/document/product/881/30820#options-.E5.8F.82.E6.95.B0.E5.88.97.E8.A1.A8
            });
            player.on("error", (err) => console.log(err));
            this.$nextTick(() => {
                // setTimeout(() => player.play(), 3000);
                this.isPushing == 1 && player.play();
            });
            this.livePusher = player;
        },
        // 录制屏幕
        recordScreen() {
           
        },
        stopResource() {
            this.liveVideo && this.liveVideo.stop();
            this.speechSDK && this.speechSDK.stop();
        },
        /**最后一步，直播准备工作 */
        async liveReadyWork(isReload = false) {
            if (this.liveType == 0) {
                this.$message.error("未选择平台直播！");
                return void 0;
            }
            this.startBtnFlag = false;
            const { setLivePusher, setSpeech } = this;
            this.$refs.liveVideo && this.$set(this.$refs.liveVideo, "innerHTML", "");
            let res = [];
            if (this.liveType == 2) {
                res = await Promise.all([setLivePusher(), setSpeech()]).catch((err) => console.log(err));
            }
            const isHttps = location.protocol.includes("https");
            const isExistScript = this.scriptCarousel.length > 0;
            res.push({ state: isHttps, msg: "请使用https协议的网址重新进入页面" });
            res.push({ state: isExistScript, msg: "本场直播暂无脚本" });
            const error = res.find((item) => !item.state);
            if (!this.isDev && error) {
                setTimeout(this.stopResource, 500);
                this.$message.warning(error.msg);
                this.startBtnFlag = true;
                return void 0;
            }
            // isReload ? this.startCountdown() : this.ws.send(JSON.stringify(this.wsActions.startLive));
            this.startCountdown();
        },
        /**点击开始直播后3秒倒计时 */
        startCountdown() {
            this.startBtnFlag = true;
            this.countdown = 3;
            this.liveState = 4;
            return new Promise((resolve, reject) => {
                const fun = () => {
                    this.countdownTimer && clearTimeout(this.countdownTimer);
                    this.countdownTimer = setTimeout(() => {
                        if (this.countdown <= 0) {
                            this.liveState = 1;

                            this.recordScreen();

                            resolve();
                        } else {
                            this.countdown--;
                            fun();
                        }
                    }, 1000);
                };
                fun();
            }).then(this.startLive);
        },
        /**正式开始直播 */
        startLive() {
            this.liveState = 1;
            // this.livePusher.startPush(this.pushUrl);
            // this.liveType == 2 ? this.liveVideo.start() : this.startCheckPushState();
            this.liveType == 2 && this.liveVideo.start();
            this.liveType == 2 && this.setScriptTimer();
        },
        /**停止直播直播 */
        stopLive() {
            this.stopResource();
            this.clearAllTimer();
            this.hiddenAllWindow();
        },
        hiddenAllWindow(status) {
            this.showPauseConfirmModal = false;
            this.showEndConfirmModal = false;
            this.showLuckyBagPopup = false;
            this.showGoodsPopup = false;
            this.showCouponPopup = false;
            this.showPauseCountdown = status == 3;
        },
        /**直播中下方动作监听 */
        transfer(handler) {
            const funMap = {
                livePause: this.LivePauseVerify,
                liveStop: this.liveStopVerify,
                luckyBag: this.openLuckyBagPopup,
                goods: this.openGoodsPopup,
                digg: this.handleDigg,
            };
            funMap[handler] && funMap[handler]();
        },
        /**显示暂停确认框 */
        LivePauseVerify() {
            this.showPauseConfirmModal = true;
        },
        /**通过开始按钮进行直播 */
        async startButtonHandler() {
            if(!this.userInfo) {
                this.$message.error("获取用户信息异常!");
                return;
            }
            const valid = await this.getAuthority();
            valid
                ? this.ws.send(JSON.stringify(this.wsActions.startLive))
                : this.$message.error("网站需要获取您的媒体权限才可正常使用");
        },
        liveStopVerify() {
            this.showEndConfirmModal = true;
        },
        sendUpdateLiveState(status) {
            const { ws, wsActions } = this;
            if (ws && ws.readyState == 1) {
                this.connectLoading = true;
                const params = Object.assign({}, wsActions.updateLiveState, { status });
                ws.send(JSON.stringify(params));
            }
        },
        openLuckyBagPopup() {
            this.showLuckyBagPopup = true;
        },
        openGoodsPopup() {
            this.showGoodsPopup = true;
        },
        handleDigg() {
            this.ws.send(JSON.stringify({ 
                type: "likes",
                room_id: this.roomId, 
                user_id: this.commonParams.user_id,
                train_id: this.commonParams.train_id,
            })); // 点赞发送ws

            const animationTime = 1 + Math.random() * 0.5;
            const likeIConItem = {
                index: new Date().getTime(),
                rotate: Math.random() * 10 + 10,
                randX: Math.random() * 100,
                randY: Math.random() * 50 + 150, 
                rightX: Math.random() * 50 + 10,
                bottomX: Math.random() * 100 + 10,
                animationTime: animationTime,
            }
            this.likeIcons.push(likeIConItem);

            setTimeout(() => {
                const iconIndex = this.likeIcons.findIndex((item) => item.index === likeIConItem.index);
                this.likeIcons.splice(iconIndex, 1);
            }, animationTime * 1000)
        },
        wsLikes(val) {
            // console.log("ws message likes: ", val);
            this.$emit("setData", val, "likes");
        },
        goodsToCouponPopup(goodsId) {
            this.couponGoodsId = goodsId;
            this.showCouponPopup = true;
        },
        openAddedCoupon() {
            this.couponOrigin = 1;
            this.showCouponPopup = true;
        },
        openAddedBag() {
            this.bagOrigin = 1;
            this.showLuckyBagPopup = true;
        },
        /**更新商品的状态 */
        updateGoodsState(goodsId, status) {
            if (this.releaseFlag) {
                this.releaseFlag = false;
                const params = {
                    goods_id: goodsId,
                    status: status,
                };
                this.ws.send(JSON.stringify(Object.assign({}, this.wsActions.updateGoodsState, params)));
            }
        },
        goodsFall(goods) {
            this.updateGoodsState(goods.goods_id, 0);
        },
        /**发布动作 */
        release({ check, item }) {
            const { currentScript, currentBag, currentGoods, getGoodsParams, getCouponParams, getBagParams } = this;
            if (check == "goods") {
                (!currentGoods || currentGoods.status == 0 || currentGoods.status == 1) && this.updateGoodsState(item.goods_id, 1);
                return void 0;
            }
            const funMap = {
                goods: getGoodsParams,
                coupon: getCouponParams,
                bag: getBagParams,
            };
            const common = {
                end: currentScript.end,
            };
            const addParams = funMap[check](currentScript, item);
            if (addParams) {
                const action = Object.assign({}, this.wsActions.operates, common, addParams);
                this.ws.send(JSON.stringify(action));
                this.ws.send(JSON.stringify(this.wsActions.getListData));
            }
        },
        getGoodsParams(currentScript, item) {
            if (this.addedGoodsList.findIndex((item) => item.status == 1) != -1) {
                this.currentGoods || this.$message.warning("请先下架商品");
                return false;
            }
            const params = {
                goods_id: item.goods_id,
                status: 1,
            };
            // const params = {
            //     genre_id: 0,
            //     genre: 6,
            //     goods_id: item.goods_id,
            //     is_correct: Boolean(currentScript.script_type == 6 && currentScript.goods_id == item.goods_id) ? 1 : 2,
            // };
            // params.is_correct == 1 && this.$set(params, "genre_id", currentScript.genre_id);
            return params;
        },
        getCouponParams(currentScript, item) {
            const params = {
                genre: 7,
                genre_id: 0,
                coupon_id: item.id,
                goods_id: item.goods_id,
                is_correct: currentScript.script_type == 7 ? 1 : 2,
            };
            params.is_correct == 1 && this.$set(params, "genre_id", currentScript.genre_id);
            this.ws.send(JSON.stringify(Object.assign({}, this.wsActions.changeCoupon, { id: item.id, status: 1 })));
            return params;
        },
        getBagParams(currentScript, item) {
            if (this.currentBag) {
                this.$message.error("福袋还未结束");
                return null;
            }
            const params = {
                genre: 8,
                genre_id: 0,
                is_correct: currentScript.script_type == 8 ? 1 : 2,
                student_bag_id: item.id,
            };
            params.is_correct == 1 && this.$set(params, "genre_id", currentScript.genre_id);
            return params;
        },
        clearResource() {
            // const { livePusher } = this;
            this.ws && this.ws.readyState == 1 && this.ws.close();
            this.speechSDK && this.speechSDK.stop();
            this.liveVideo && this.liveVideo.stop();
            // if (livePusher) {
            //     livePusher.stopPush();
            //     livePusher.stopCamera();
            //     livePusher.stopMicrophone();
            // }
        },
        popupAnimateEnd() {
            this.couponGoodsId = "";
            this.couponOrigin = 0;
            this.bagOrigin = 0;
        },
        clearAllTimer(type) {
            const { adTimer, bagTimer, beatTimer, scriptTimer, answerTimer, bulletChatTimer } = this;
            [adTimer, bagTimer, beatTimer, scriptTimer, answerTimer, bulletChatTimer.answer, bulletChatTimer.normal].forEach(
                (item) => item && clearTimeout(item)
            );
        },
        async getAuthority() {
            return new Promise((resolve, reject) => {
                if (this.liveType == 1) {
                    resolve(true);
                    return void 0;
                }
                if (!navigator) {
                    this.$message.error("无法获取媒体设备");
                    resolve(false);
                    return void 0;
                }
                navigator.getUserMedia(
                    { video: true, audio: true },
                    (stream) => resolve(true),
                    (error) => {
                        console.log(error);
                        resolve(false);
                    }
                );
            });
        },
    },
    mounted() {
        this.init();
        that = this;
        if (this.isDev) {
            // this.scriptIndex = 5;
            // setTimeout(() => {
            //     this.listInfo.goodsList.forEach((item) =>
            //         this.ws.send(
            //             JSON.stringify(Object.assign({}, this.wsActions.updateGoodsState, { goods_id: item.goods_id, status: 0 }))
            //         )
            //     );
            // }, 2000);
        }
    },
    beforeDestroy() {
        this.liveState = 2;
        this.clearResource();
        this.clearAllTimer();
    },
};
</script>

<style scoped lang="scss">
.live-main {
    position: relative;
    box-sizing: border-box;
    width: 562px;
    height: 100%;
    margin: 0 20px;
    overflow: hidden;
    .mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 999;
    }
    .bg-img-box {
        .bg-img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        .pull-hint {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-size: 22px;
            color: #666;
            background-color: rgba(0, 0, 0, 0.9);
            // animation: 3s ease-in 1s infinite reverse both running slidein;
            // animation: 1s linear 0s infinite normal forwards running PullHint;
        }
    }

    .bg-blur {
        filter: blur(5px);
    }
    .countdown {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 140px;
        height: 140px;
        line-height: 140px;
        text-align: center;
        font-size: 80px;
        color: #fff;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }
    .container {
        width: 100%;
        height: 100%;
        position: relative;

        .live-video-container-box {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .content {
            box-sizing: border-box;
            display: flex;
            width: 100%;
            height: 100%;
            padding: 12px 10px;
            .state {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            .center {
                flex: 1;
            }
        }
        .play-controls {
            padding-bottom: 36px;
        }

        .like-container {
            color: #f6c656;
            position: absolute;
            width: calc(100% - 120px);
            height: 30%;
            // background-color: gray;
            right: 60px;
            bottom: 360px;

            .like-icon {
                position: absolute;
                right: var(--right-x);
                bottom: var(--bottom-x);
                rotate: var(--rotate);
                animation: floatUp var(--animation-time) ease-out forwards;

                img {
                    width: 110px;
                    height: 110px;
                    object-fit: cover;
                }
            }

            @keyframes floatUp {
                0% {
                    opacity: 1;
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(var(--rand-y)) translateX(calc(-200px - var(--rand-x)));
                    opacity: 0;
                }
            }
        }
    }
}
// @keyframes PullHint {
//     0% {
//         content: "加载中.";
//     }
//     33% {
//         content: "加载中..";
//     }
//     66% {
//         content: "加载中...";
//     }
//     99% {
//         content: "加载中.";
//     }
// }

</style>
<style lang="scss">
#live-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
    }
}
#live-video-container-box .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1 !important;
}
</style>
